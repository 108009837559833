import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import WinItLogo from '../assets/images/navigation-bar-logo-portal.svg'

const TIMER_DURATION = 5
const ONE_SECOND = 1000

const getRedirectLink = () => {
  const environment = process.env.REACT_APP_ENV

  switch (environment) {
    case 'production':
      return 'https://admin.appwinit.com'
    case 'staging':
      return 'https://staging.admin.appwinit.com'
    default:
      return 'https://admin.appwinit.com'
  }
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-block: 80px;
  margin-inline: 8px;
  gap: 160px;
`

const Logo = styled.img`
  width: 200px;
  height: 128px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  p {
    text-align: center;
    margin-bottom: 0;
  }
`

const Timer = styled.span`
  color: #999999;
  font-size: 72px;
`

const Notice = styled.p`
  font-size: 24px;
`

const TipContainer = styled.p`
  font-size: 18px;
  color: #666666;
`

const TipWord = styled.span`
  font-weight: 600;
`

const NewAPRedirection = () => {
  const [timer, setTimer] = useState(TIMER_DURATION)
  const timerRef = useRef()

  const stopTimer = () => clearInterval(timerRef.current)

  useEffect(() => {
    timerRef.current = setInterval(
      () => setTimer(currentTimer => currentTimer - 1),
      ONE_SECOND
    )

    return stopTimer
  }, [])

  useEffect(() => {
    if (timer > 0) return

    const redirectLink = getRedirectLink()
    window.location.href = redirectLink

    stopTimer()
  }, [timer])

  return (
    <PageContainer>
      <Logo src={WinItLogo} />

      <TextContainer>
        <Timer>{timer}</Timer>

        <Notice>
          In a few seconds, you will be redirected to the new Attorney Platform
        </Notice>

        <TipContainer>
          <TipWord>Tip:</TipWord> The login credentials are the same
        </TipContainer>
      </TextContainer>
    </PageContainer>
  )
}

export default NewAPRedirection
